<template>
  <ws-select
    :class="$style.select"
    v-bind="$attrs"
    :classes="$style"
    :offset="[0, 5]"
    v-on="$listeners"
  >
    <template #selected-option-container>
      <div :class="$style.select__reference" class="flex flex-center">
        <slot></slot>
      </div>
    </template>
  </ws-select>
</template>

<script>
import WsSelect from "@/components/base/WsSelect";

export default {
  name: "WsIconSelect",
  components: {
    WsSelect,
  },
  inheritAttrs: false,
  computed: {
    classes() {
      return {
        select_open: this.$style.select_open,
        select_dropUp: this.$style.select_dropUp,
        dropDownOpenUp: this.$style.dropDownOpenUp,
        dropdown: this.$style.dropdown,
      };
    },
  },
};
</script>

<style lang="scss" module>
.select {
  display: inline-block;

  &__reference {
    width: 38px;
    height: 38px;
  }
}

.select :global(.vs__dropdown-toggle) {
  width: auto;
  min-height: auto;
}

.select :global(.vs__actions) {
  display: none;
}

.select :global(.vs__selected-options) {
  padding: 0;
}

.dropdown,
.dropdownOpenUp,
.select :global(.vs__dropdown-menu) {
  border: 1px solid $gray;
  border-radius: 5px;
}

.select:not(:global(.vs--disabled)) :global(.vs__dropdown-toggle),
.select:global(.vs--disabled) :global(.vs__dropdown-toggle),
.select:global(.vs--disabled) :global(.vs__search) {
  padding: 0;
  background: transparent;
  border: none;
}

.select:global(.vs--disabled) * {
  color: $gray;
  cursor: not-allowed;
}

.dropdown {
  min-width: 200px;
  padding: 0;

  &[data-popper-reference-hidden],
  &[data-popper-escaped] {
    display: none;
  }
}
</style>
