<template>
  <div>
    <not-exist-schedule-items v-if="!isExistItems"></not-exist-schedule-items>
    <ul v-else class="agenda-list">
      <template v-for="item in items">
        <agenda-details :key="item.id" :item="item" />
      </template>
    </ul>
  </div>
</template>
<script>
import AgendaDetails from "./AgendaDetails";
import NotExistScheduleItems from "./NotExistScheduleItems";

export default {
  name: "ScheduleList",
  components: { AgendaDetails, NotExistScheduleItems },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    isExistItems() {
      return this.items && this.items.length;
    },
  },
};
</script>
<style lang="less" scoped>
.agenda-list {
  padding: 0;
  margin: 0;
}
</style>
