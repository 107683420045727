<template>
  <section class="agenda">
    <div class="agenda__container">
      <loader v-if="pending" class="loader" />
      <not-exist-schedule-items v-else-if="!isHasSchedule"></not-exist-schedule-items>
      <template v-else>
        <agenda-filter
          class="agenda__header"
          :current-date="currentDate"
          :hall-id="hallId"
          @handleSelectionDates="setCurrentDateId"
          @handleSelectionHalls="setHallId"
        />
        <schedule-list :items="items" />
      </template>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import ScheduleList from "@/components/speaker/Agenda/components/ScheduleList";
import { room, schedule } from "@/store/modules/store.namespaces";
import { DATES_SORT, EVENTS } from "@/store/modules/schedule/getter-types";
import { GET_SCHEDULE } from "@/store/modules/schedule/action-types";
import NotExistScheduleItems from "./components/NotExistScheduleItems";
import AgendaFilter from "./components/AgendaFilter";
import Loader from "../../common/elements/Loader";

export default {
  name: "Agenda",
  components: {
    ScheduleList,
    Loader,
    AgendaFilter,
    NotExistScheduleItems,
  },
  data() {
    return {
      currentDate: null,
      hallId: "all",
    };
  },
  computed: {
    ...mapState(room, {
      room: "roomNumber",
    }),
    ...mapGetters(schedule, {
      events: EVENTS,
      datesSort: DATES_SORT,
    }),
    ...mapState(schedule, {
      pending: "agendaLoadingStatus",
      schedule: state => state.schedule.halls || [],
    }),
    items() {
      return this.events(this.currentDate?.id || null, this.hallId) || [];
    },
    isHasSchedule() {
      return !!(this.schedule && this.schedule.length);
    },
    dates() {
      return this.datesSort(this.hallId) || [];
    },
  },
  watch: {
    room: {
      handler(value) {
        if (value) {
          this.init();
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions(schedule, { getScheduleByHalls: GET_SCHEDULE }),
    init() {
      this.getScheduleByHalls().then(() => {
        this.setDefaultDate();
      });
    },
    setDefaultDate() {
      if (this.dates && this.dates.length) {
        const date = this.findNearestDate(this.dates);
        this.setCurrentDateId(date || null);
      }
    },
    findNearestDate(dates) {
      const today = this.$moment().format("YYYY-MM-DD");
      dates.sort((a, b) => {
        return Number(this.$moment(a.date).format("x")) - Number(this.$moment(b.date).format("x"));
      });
      let nearestDate = dates.find(item => {
        const date = this.$moment(item.date, "DD MMM YYYY HH:mm").format("YYYY-MM-DD");
        return this.$moment(date).isSameOrAfter(today);
      });

      if (!nearestDate) {
        nearestDate = dates.reverse().find(item => {
          const date = this.$moment(item.date, "DD MMM YYYY HH:mm").format("YYYY-MM-DD");
          return this.$moment(date).isSameOrBefore(today);
        });
      }

      return nearestDate;
    },
    setCurrentDateId(value) {
      this.currentDate = value;
    },
    setHallId(value) {
      this.hallId = value;
      this.setDefaultDate();
    },
  },
};
</script>

<style lang="less" scoped>
.loader {
  margin-top: 20px;
}

.agenda {
  display: flex;
  justify-content: center;
  height: 100%;
  padding: 20px 0;

  &__container {
    width: 100%;
    height: 100%;
    max-width: 560px;
  }

  &__header {
    min-width: 560px;
    width: 100%;
  }
  @media (max-width: 768px) {
    padding-right: 15px;
    padding-left: 15px;
    background-color: #f6f6f6;
    &__header {
      min-width: initial;
    }
  }
}
</style>
