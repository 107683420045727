<template>
  <li class="agenda-details">
    <article>
      <header class="agenda-details__header">
        <span class="agenda-tag agenda-tag--time">{{ item.time }}</span>
        <span v-if="showHallName" class="agenda-tag agenda-tag--place">{{ item.hall_name }}</span>
      </header>
      <p class="agenda-details__description">{{ item.name }}</p>
      <div v-if="speakers && speakers.length" class="agenda-speaker">
        <template v-for="speaker in speakers">
          <div :key="speaker.id">
            <p class="agenda-speaker__name">{{ speaker.firstname }}</p>
            <p class="agenda-speaker__position">{{ speaker.position }}</p>
          </div>
        </template>
      </div>
    </article>
  </li>
</template>

<script>
const COMMON_HALL_ID = 0;
export default {
  name: "AgendaDetails",
  props: {
    item: {
      type: Object,
    },
  },
  computed: {
    /**
     * Сортировка спикеров по order.
     *
     * TODO: Временное решение, потом необходимо убрать.
     *
     * @returns {Array} arrSpeakers - возвращает массив отсортированных спикеров.
     */
    speakers() {
      const arrSpeakers = [...this.item?.aux_info];
      arrSpeakers?.sort((a, b) => a.order - b.order);
      return arrSpeakers || [];
    },
    showHallName() {
      return this.item.hall_name && this.item.hall_id !== COMMON_HALL_ID;
    },
  },
};
</script>

<style lang="less" scoped>
.agenda-details {
  max-width: 560px;
  padding: 9px 8px;
  border-radius: 6px;
  border: 1px solid #999999;
  &:not(:last-child) {
    margin-bottom: 15px;
  }

  &__description {
    line-height: 16px;
  }
  @media (max-width: 640px) {
    padding: 8px 8px 6px;
    border: none;
    background: #ffffff;

    &:not(:last-child) {
      margin-bottom: 5px;
    }

    &__description {
      margin-top: 6px;
      margin-bottom: 11px;
      font-size: 15px;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
}
.agenda-tag {
  display: inline-block;
  color: #ffffff;
  text-align: center;
  font-size: 14px;
  &--time {
    height: 18px;
    padding-top: 1px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: #f07300;
    border-radius: 9px;
  }

  &--place {
    height: 17px;
    padding-left: 16px;
    padding-right: 16px;
    background-color: #a0a0a0;
    border-radius: 17px;
  }
}
.agenda-speaker {
  max-width: 360px;
  &__name {
    margin-top: 0;
    margin-bottom: 5px;
  }
  &__position {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 14px;
    color: #838383;
  }
  @media (max-width: 640px) {
    max-width: 100%;
    margin-top: 0;
  }
}
</style>
