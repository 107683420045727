<template>
  <header :class="$style.wrapper" class="flex justify-between">
    <ws-select
      :class="$style.dates"
      :options="dates"
      :value="currentDate"
      @input="handleSelectionDate"
    />
    <ws-icon-select :options="hallsOptions" :value="hallId" @input="handleSelectionHall">
      <ws-icon regular lg color="primary">sliders-v</ws-icon>
    </ws-icon-select>
  </header>
</template>

<script>
import { mapGetters } from "vuex";
import { DATES_SORT, HALLS_SORT } from "@/store/modules/schedule/getter-types";
import { schedule } from "@/store/modules/store.namespaces";
import WsIconSelect from "@/components/base/WsIconSelect";
import WsIcon from "@/components/base/WsIcon";
import WsSelect from "@/components/base/WsSelect";

export default {
  name: "AgendaFilter",
  components: {
    WsIconSelect,
    WsIcon,
    WsSelect,
  },
  props: ["currentDate", "hallId"],
  computed: {
    ...mapGetters(schedule, {
      hallsOptions: HALLS_SORT,
      datesSort: DATES_SORT,
    }),
    dates() {
      return this.datesSort(this.hallId);
    },
  },
  methods: {
    handleSelectionDate(date) {
      this.$emit("handleSelectionDates", date);
    },
    handleSelectionHall(hall) {
      this.$emit("handleSelectionHalls", hall.id);
    },
  },
};
</script>

<style lang="scss" module>
.wrapper {
  margin-bottom: 11px;

  @include mq-tablet {
    margin-bottom: 33px;
  }
}

.dates {
  min-width: 200px;
}
</style>
