<template>
  <p class="agenda-message">{{ $t("SpeakerAgenda.scheduleNotSetYet") }}</p>
</template>

<script>
export default {
  name: "NotExistScheduleItems",
};
</script>
<style lang="less" scoped>
.agenda-message {
  text-align: center;
}
</style>
